import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Layout';
import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Button } from 'ui/Button/Button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'ui/Form/Form';
import { Input } from 'ui/Input/Input';
import { z } from 'zod';

const useSchemeWishlistForm = () => {
  const { formatMessage } = useIntl();
  return z.object({
    description: z.string().min(1, { message: formatMessage({ id: 'form_validation_required' }) }),
    title: z.string().min(1, { message: formatMessage({ id: 'form_validation_required' }) }),
  });
};

interface WishlistFormModalProps {
  buttonCopy?: string;
  callback: (values?: Record<string, string>) => void;
  closeModal: () => void;
  initialForValues?: { [key: string]: unknown };
  show: boolean;
  side?: 'left' | 'right';
  title?: string;
  wishlistDescription?: string;
  wishlistTitle?: string;
}

const WishlistFormModal = ({
  buttonCopy,
  callback,
  closeModal,
  show,
  side,
  title,
  wishlistDescription,
  wishlistTitle,
}: WishlistFormModalProps) => {
  const { formatMessage } = useIntl();

  const formSchema = useSchemeWishlistForm();
  const form = useForm({
    resolver: zodResolver(formSchema),
    values: { description: wishlistDescription ?? '', title: wishlistTitle ?? '' },
  });

  if (!show) return null;

  return (
    <Modal
      className="flex flex-col gap-2"
      closeModal={closeModal}
      show={show}
      title={title}
      side={side}
      flyOverOnMobile={false}
    >
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((values) => {
            callback(values);
            closeModal();
          })}
          className="space-y-4"
        >
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  {formatMessage({
                    id: 'wish_list_edit_form_title',
                  })}
                </FormLabel>
                <FormControl>
                  <Input {...field} type="text" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  {formatMessage({
                    id: 'wish_list_edit_form_description',
                  })}
                </FormLabel>
                <FormControl>
                  <Input {...field} type="text" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button disabled={form.formState.isSubmitting} type="submit" className="w-full">
            {buttonCopy}
          </Button>
        </form>
      </Form>
    </Modal>
  );
};

export default memo(WishlistFormModal, isEqual);
